@mixin mq($breakpoint: pc) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin lang-en {
  font-family: 'Montserrat', sans-serif;
}

@mixin shadow {
	box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
}
