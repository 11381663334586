@charset "utf-8";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: auto;
  font-size: 62.5%;
  font-family: 'Montserrat',"Noto Sans JP", "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
  @include mq(sp) {
    // font-size: 1.4rem;
  }
}

button {
  -webkit-appearance: button;
  outline: none;
  border: none;
}

body {
  width: 100%;
  line-height: 1.91;
  color: $colorParagraph;
  word-wrap: break-word;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  position: relative;
  font-size: 1.4rem;
  @include mq(pc) {
    min-width: 1120px;
  }
  @include mq(sp) {
    font-size: 1.4rem;
  }
  &.-is-open {
    position: fixed;
    overflow: hidden;
  }
}

a {
  color: $colorParagraph;
  text-decoration: none;
  transition: all .3s;
  // &:hover,
  // &:active,
  // &:focus {
  //   opacity: .5;
  // }
}

@include mq(pc) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

// IE11 SVGサイズ崩れハック
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src$='.svg'] {
    width: 100%;
    height: auto;
  }
}
.sp {
  display: none;
  @include mq(sp) {
    display: block;
  }
}

.pc {
  @include mq(sp) {
    display: none;
  }
}