.c-service-example__inner {
	@include mq(pc) {
		display: flex;
	}
}

.c-service-example__item {
	color: #ffffff;
	background: url(/assets/images/renewal/service/bg_performance_01.jpg) no-repeat top right / cover;
	box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
	@include mq(pc) {
		height: 398px;
		padding-top: 90px;
		flex-basis: 50vw;
		max-width: 50vw;
	}
	&:first-child {
		.c-service-example__item-inner {
			@include mq(pc) {
				padding-right: 100px;
				margin-left: auto;
		}
	}
}
&:nth-of-type(2) {
	background: url(/assets/images/renewal/service/bg_performance_02.jpg) no-repeat top left / cover;
	.c-service-example__item-inner {
		@include mq(pc) {
			padding-left: 100px;
		}
	}
}
}

.c-service-example__heading {
	font-size: 2.4rem;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.c-service-example__lead {
	margin-top: 50px;
	@include mq(sp) {
		margin-top: 20px;
	}
}

.c-service-example__item-inner {
	@include mq(pc) {
		max-width: 440px;
	}
	@include mq(sp) {
		padding: 30px 20px;
	}		
}