.p-about {
  .l-main {
    overflow-x: hidden;    
  }

  .p-keyVisual {
    position: relative;
    background: url(/assets/images/renewal/about/bg_about_01.jpg) top center;
    @include mq(sp) {
      background: none;
    }
  }

  .about-btn {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);
    @include mq(sp) {
      width: 34vw;
      bottom: 4vw;
    }
  }

  .p-catch {
    position: relative;
  }

  .p-fleet {
    position: relative;
  }

  .p-price {
    position: relative;
    .about-btn {
      bottom: 50px;
      @include mq(sp) {
        bottom: 2vw;
      }
    }
  }

  .p-deal {
    position: relative;
    background: url(/assets/images/renewal/about/bg_about_02.jpg) no-repeat bottom center;
    @include mq(sp) {
      background: none;
    }
  }
}

