//タブ
.c-tab {
  display: flex;
  @include mq(pc) {
    margin: 0 -10px;
  }
  &.-tab-column--03 {
    @include mq(sp) {
      flex-wrap: wrap;
      .c-tab-controller__item  {
        &:nth-child(2) {
          .c-tab-controller__btn {
            border-left: 1px solid #ddd;
          }
        }
      }
    }
    .c-tab-controller__item {
      flex-basis: calc(100% / 3);
      width: calc(100% / 3);
      margin: 0 10px;
      @include mq(sp) {
        margin: 0;
        flex-basis: calc(100% / 2);
        width: calc(100% / 2);
        &:last-child {
          flex-basis: 100%;
          width: 100%;
        }
			}
    }
	}
	&.-tab-column--04 {
		margin: 0;
		@include mq(sp) {
			flex-wrap: wrap;
		}
    .c-tab-controller__item {
      flex-basis: calc(100% / 4);
      width: calc(100% / 4);
      @include mq(sp) {
        margin: 0;
        flex-basis: calc(100% / 2);
        width: calc(100% / 2);
      }
		}
	}
}

.c-tab-controller {
  max-width: 1120px;
  margin: 0 auto;
}

.c-tab-controller__btn {
  position: relative;
  padding: 10px 0;
  color: $primaryColor;
  font-size: 1.8rem;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  line-height: 1.33;
  width: 100%;
  height: 100%;
  background: none;
  transition: $transition;
  @include mq(sp) {
    border-top: 1px solid #ddd;
    &[aria-controls="tab-panel-ETC"] {
      border-top: none;
    }
  }
  &:hover {
    cursor: pointer;
    background: $primaryColor;
    color: #fff;
    border-bottom: 1px solid $primaryColor;
    .icon-arrow {
      fill:#fff;
    }    
  }
  &[aria-selected="true"] {
    background: $primaryColor;
    color: #fff;
    border-bottom: 1px solid $primaryColor;
    .icon-arrow {
      fill:#fff;
    }
  }
  .icon-arrow {
    position: absolute;
    width: 18px;
    height: 18px;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    fill:$primaryColor;
    @include mq(sp) {
      right: 10px;
    }
  }
}

.c-tab-panel {
  margin-top: 50px;
  @include mq(sp) {
    margin-top: 16px;
  }
}

.c-tab-panel__item {
  &[aria-hidden="true"] {
    display: none;
  }
  &[aria-hidden="false"] {
    display: block;
  }
}

//タブとデザイン変わらないがただのアンカーボタン
.c-tab-anchor {
	&:not(:last-of-type) {
		.c-tab-controller__btn {
			@include mq(pc) {
				border-right: 2px solid #bfbfbf;
			}
		}
	}
	&:not(:first-child) {
		.c-tab-controller__btn {
			@include mq(pc) {
				border-left: none;
			}			
		}
	}
	.c-tab-controller__btn {
		padding: 25px 0 25px 0;
		background: #fff;
		color: $primaryColor;
		border: 1px solid #666666;
		@include mq(pc) {
			display: flex;
			align-items: center;
			justify-content: center;		
		}
		@include mq(sp) {
			display: block;
			padding: 25px 10px 25px 0;
			font-size: 1.4rem;
		}
		&:hover {
			background: $primaryColor;
			color: #fff;
		}
		.icon-arrow {
			@include mq(pc) {
				margin-left: 14px;
				position: static;
				transform: translateY(0);
			}
		}
	}
}
