.-link-arrow {
  .arrow-right {
    width: 15px;
    height: 10px;
    margin-right: 14px;     
  }
}

.c-intro {
	padding: 50px 0;
	font-size: 1.8rem;
	font-weight: bold;
}

.c-module-tel {
  font-size: 4.8rem;
  @include lang-en;
  font-weight: 800;
  line-height: 1;
  color: #fff;
  display: flex;
	justify-content: center;
	align-items: center;
	fill:#fff;
  @include mq(sp) {
    font-size: 4rem;
    letter-spacing: -1.5px;
  }
  .c-module-tel__item {
    display: flex;
    align-items: center;
		&:last-child {
			font-size: 4rem;
			@include mq(sp) {
				font-size: 3.6rem;
			}
		}
    @include mq(sp) {
			display: block;
      text-align: center;
      justify-content: center;
			a {
				display: block;
			}
    }
		.c-module-tel__txt {
			font-size: 2rem;
			margin-right: 15px;
			@include mq(sp) {
				margin-right: 0;
			}
		}
  }

  .icon-tel-box {
    width: 42px;
    height: 42px;
    margin-right: 17px;
    @include mq(sp) {
      width: 30px;
      height: 30px;
      margin-right: 12px;
    }
  }
}

.c-footer {
  .c-module-tel {
    margin-top: 15px;
    justify-content: center;
  }
}

.c-module-conversion {
  padding: 25px 0;
  @include mq(pc) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @include mq(sp) {
    padding: 34px 0;
  }
  .c-module-tel {
    margin-top: 3px;
  }
  .c-button-box {
    @include mq(sp) {
      margin-top: 40px;
    }
  }
}
.c-module-conversion-tel {
  margin-right: 14px;
  @include mq(sp) {
    margin-right: 0;
    text-align: center;
  }
  .c-infomation-tel__lead {
    font-size: 1.4rem;
  }  
}
.c-module-conversion__note {
  margin-top: 10px;
  text-align: center;
  flex-basis: 100%;
  width: 100%;
  font-size: 1.2rem;
  @include mq(sp) {
    margin-top: 28px;
    font-size: 1.2rem;
  }
}

.c-company-info__logo {
  flex: 0 0 225px;
  max-width: 225px;
  @include mq(sp) {
		margin: 0 auto;
		max-width: 150px;
  }
}

.c-company-info__txt {
  @include mq(pc) {
    margin-left: 104px;
	}
	@include mq(sp) {
		margin-top: 20px;
	}
}

.c-company-info__heading {
  font-size: 2.4rem;
	font-weight: bold;
	@include mq(sp) {
		font-size: 2rem;
	}
}

.c-company-info__link {
	font-size: 1.4rem;
	&:hover {
		opacity: .7;
	}
  .external-link {
    width: 14px;
    height: 12px;
  }
}

.c-company-box {
  display: flex;
  align-items: center;
  padding: 35px 30px 35px 75px;
  background: #fff;
  max-width: 1020px;
  margin: 0 auto;
  @include mq(sp) {
    display: block;
    width: 350 / (400 / 100vw);
		padding: 35px 20px;
  }
}

.c-company-info__lead {
	margin-top: 40px;
	@include mq(sp) {
		margin-top: 25px;
	}
}

.c-company-info__more {
  display: block;
  margin-top: 15px;
}

.c-contact {
  text-align: center;
  background: $primaryColor;
	color: #ffffff;
	.c-contact__lead {
		font-size: 1.6rem;
		line-height: 1.63;
	}
}

.c-contact__heading {
  margin-bottom: 30px;
  font-size: 3.6rem;
	font-weight: bold;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.c-icon-feature {
  @include mq(pc) {
    display: flex;
    text-align: center;
  }
  &--3col {
    .c-icon-feature__item {
      padding: 30px 75px 0;
      @include mq(pc) {
        flex-basis: 372px;
        max-width: 372px;
			}
			@include mq(sp) {
				padding: 30px 25px 0;
			}
    }
  }
}

.c-icon-feature__item {
  position: relative;
  @include mq(pc) {
    &::after {
      position: absolute;
      content: "";
      background: #dddddd;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 100px;
      width: 1px;
    }
  }
  &:first-child {
    @include mq(pc) {
      &::before {
        position: absolute;
        content: "";
        background: #dddddd;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100px;
        width: 1px;
      } 
    }
  }
  &:nth-child(n+2) {
    @include mq(sp) {
      margin-top: 50px;
    }
  }
  .c-number-card__heading {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 50%;
      border: 16px solid #eee;
      width: 200px;
      height: 200px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      z-index: -1;
		}
		img {
			@include mq(sp) {
				width: 30%;
			}
		}
  }
}

.c-icon-feature__heading {
  @extend .c-number-card__ttl;
}

.c-icon-feature__lead {
  margin-top: 30px;
	text-align: left;
	font-size: 1.6rem;
}

.c-point-article {
	position: relative;
	padding: 120px 60px 100px;
	background: #fff;
	@include mq(sp) {
		padding: 100px 20px 60px;
	}
	& + & {
		margin-top: 190px;
		@include mq(sp) {
			margin-top: 120px;
		}
	}
}

.c-point-article__catch {
	position: absolute;
	font-size: 2.2rem;
	font-weight: bold;
	line-height: 1.27;
	letter-spacing: 0.44px;
	text-align: center;
	bottom: 100%;
	background: #fff;
	border-radius: 50%;
	width: 200px;
	height: 200px;
	border: 16px solid #d1ba7d;
	display: flex;
	flex-direction: column;
	justify-content: center;
	transform: translate(-50%, 100px);
	left: 50%;
	@include mq(sp)	{
		width: 160px;
		height: 160px;
		transform: translate(-50%, 80px);
		border: 13px solid #d1ba7d;
		font-size: 1.8rem;
	}
	.number {
		margin-right: 10px;
		display: block;
		font-size: 5rem;
		font-style: italic;
		font-weight: 500;
		letter-spacing: normal;
		@include mq(sp)	{
			font-size: 4rem;
		}
	}
}

.c-point-article__heading {
	font-size: 2.8rem;
	font-weight: bold;
	text-align: center;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.c-point-article__kv {
	margin-top: 40px;
	color: #fff;
	display: flex;
	align-items: center;	
	@for $i from 1 through 3 {
		&.-article-0#{$i} {
			@include mq(pc) {
				background: url(/assets/images/renewal/beginners/bg_beginners_point_0#{$i}_pc.jpg) no-repeat top  center / cover;
			}
			@include mq(sp) {
				position: relative;
				padding-bottom: 45vw;
				&::after {
					position: absolute;
					content:"";
					background: url(/assets/images/renewal/beginners/bg_beginners_point_0#{$i}_pc.jpg) no-repeat bottom center / cover;
					width: 100%;
					left: 0;
					bottom: 0;
					height: 45vw;
				}
			}
		}
	}
	@include mq(pc) {
		height: 320px;
	}
	@include mq(sp) {
		margin-top: 20px;
		margin-left: -20px;
		margin-right: -20px;
	}
}

.c-point-article__kv-box {
	padding: 35px 0;
	background: rgba(#000,.7);
	width: 490px;
	text-align: center;
	margin-left: 50px;
	@include mq(sp) {
		padding: 40px 5vw 40px;
		background:$primaryColor;
		width: 100%;
		margin-left: 0;
	}
}

.c-point-article__heading-secondary {
	font-size: 3rem;
	font-weight: bold;
	line-height: 1.5;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.c-point-card {
	margin: 40px -25px 40px;
	@include mq(pc) {
		display: flex;
	}
	@include mq(sp) {
		margin-left: 0;
		margin-right: 0;
	}
}

.c-point-card__item {
	margin: 0 25px;
	padding: 30px;
	background: #eff0f0;
	box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
	@include mq(pc) {
		flex: 0 1 300px;
		max-width: 300px;
	}
	@include mq(sp) {
		margin: 0;
		padding: 25px;
	}
	&:nth-child(n+2) {
		@include mq(sp) {
			margin-top: 20px;
		}		
	}
}

.c-point-card__heading {
	font-size: 2.4rem;
	font-weight: bold;
	text-align: center;
	line-height: 1.54;
	@include mq(sp) {
		font-size: 2.2rem;
	}
	& + .c-point-card__lead {
		margin-top: 15px;
	}
}

.c-point-article__lead {
	margin-top: 10px;
	@include mq(sp) {
		margin-top: 15px;
		text-align: left;
	}
}

.c-point-article__info {
	margin-top: 50px;
	.c-infomation-tel__lead {
		font-weight: bold;
		text-align: center;
	}
	.c-module-tel {
		margin-top: 20px;
		text-align: center;
		color: $primaryColor;
		@include mq(sp) {
			font-size: 3rem;
		}
	}
}

.c-point-article__summary {
	text-align: center;
	font-weight: bold;
	font-size: 2.8rem;
	line-height: 1.32;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.p-beginners-point {
	margin-top: 150px;
	@include mq(sp) {
		margin-top: 120px;
	}
}

.c-block-step {

}

.c-block-step__number {
	text-align: center;
	background: #d1ba7d;
	color: #fff;
	padding: 16px 0;
	flex: 0 0 76px;
	max-width: 76px;
	margin-right: 50px;
	line-height: 1;
	font-weight: 500;
	font-size: 1.4rem;
	@include mq(sp) {
		padding: 10px 0;
		flex: 0 0 20%;
		max-width: 20%;
		margin-right: 10px;
	}
	span {
		display: block;
		font-size: 3.5rem;
		font-weight: bold;
		@include mq(sp) {
			font-size: 2rem;
		}
	}
}

.c-block-step__item {
	position: relative;
	display: flex;
	align-items: center;
	padding: 12px;
	@include shadow;
	font-size: 2.2rem;
	font-weight: bold;
	@include mq(sp) {
		font-size: 1.4rem;
	}
	&:nth-child(n+2) {
		margin-top: 50px;
		&::after {
			content: "";
			position: absolute;
			background: url(/assets/images/renewal/common/arrow-point-to-right.png) no-repeat 0 0;
			width: 20px;
			height: 12px;
			left: 50%;
			transform: translateX(-50%);
			bottom: calc(100% + 17px);
		}
	}
}

.c-products-deal__inner {
	@include mq(pc) {
		margin-left: -10px;
		margin-right: -10px;
	}
}

.c-service-example {
	.c-section__lead {
		@include mq(sp) {
			width: 87.5vw;
		}
	}
}