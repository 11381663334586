.c-table {
	width: 100%;
	box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
	td,th {
		text-align: center;
		border: 2px solid #dddddd;
		vertical-align: middle;
	}
}

.c-table-scroll {
	box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
	@include mq(sp) {
		margin-right: -6.25vw;
	}
	.c-table {
		box-shadow: none;
		@include mq(sp) {
			width: 200%;
		}
	}
}

.c-table-item--2col {
	width: 50%;
}

.c-table-item--3col {
	width: calc(100% /3);
}

.c-table__heading-primary {
	padding: 25px 0;
	font-size: 2.4rem;
	font-weight: bold;
	@include mq(sp) {
		font-size: 2rem;
	}
}

.c-table__heading-secondary {
	padding: 15px 0;
	font-weight: bold;
	font-size: 2.4rem;
	@include mq(sp) {
		font-size: 1.8rem;
		line-height: 1.4;
	}
}

.c-table-element {
	padding: 16px 0;
	font-size: 1.8rem;
	@include mq(sp) {
		font-size: 1.6rem;
		line-height: 1.4;
	}
}

.c-table-note {
	margin-top: 40px;
	padding: 20px 30px;
	border: 1px solid #000;
}