.c-top-news {
  @include mq(pc) {
    display: flex;
    padding: 40px 80px 50px 70px;
    background: #fff;
  }
  @include mq(sp) {
  }
  .c-title {
    @include lang-en;
    font-weight: 800;
    @include mq(pc) {
      font-size: 2.4rem;
      text-align: left;
    }
  }
  .c-title--ja {
    @include mq(pc) {
      font-size: 1.6rem;
    }
  }
}

.c-top-news__table {
  width: 100%;
  @include mq(pc) {
    margin-left: 62px;
  }
  @include mq(sp) {
    margin-top: 35px;
    padding: 20px;
    background: #ffffff;
  }
}

.c-top-news__item {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 15px 0;
  border-bottom: 1px solid #ddd;
  @include mq(pc) {
    display: flex;
  }
  @include mq(sp) {
    display: block;
  }
}

.c-top-news__time {
  @include mq(pc) {
    margin-right: 48px;
  }
}

.c-top-news__txt {
  font-weight: normal;
}
