
.slick-slide {
  outline:none;
}
.c-slider-case__keyVisual {
  position: relative;
  margin: 0 auto;
  @include mq(pc) {
    width: 1000px;
  }
  .slick-arrow {
    position: absolute;
    width: 48px;
    height: 48px;
    background: none;
    top: 50%;
    transform: translateY(-50%);
    padding: 0;
    z-index: 10;
    @include mq(sp) {
      top: 38%;
      width: 25px;
      height: 25px;
    }
    &.slick-prev {
      left: auto;
      @include mq(pc) {
        right: calc(100% + 12px);
      }
      @include mq(sp) {
        left: 10px;
        z-index: 10;
      }
      &::before {
        content:"";
        position: absolute;
        background: url('/assets/images/renewal/top/circle-arrow-left.svg') no-repeat 0 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include mq(sp) {
          background: url('/assets/images/renewal/top/circle-arrow-left_white.svg') no-repeat 0 0;
        }        
      }
    }
    &.slick-next {
      right: auto;
      @include mq(pc) {
        left: calc(100% + 12px);
      }
      @include mq(sp) {
        right: 10px;
        z-index: 10;
      }
      &::before {
        content:"";
        position: absolute;
        background: url('/assets/images/renewal/top/circle-arrow-right.svg') no-repeat 0 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        @include mq(sp) {
          background: url('/assets/images/renewal/top/circle-arrow-right_white.svg') no-repeat 0 0;
        }
      }      
    }
    svg {
      width: 100%;
      height: 100%;
      z-index: 10000;
      background: red;
      display: block;
      @include mq(pc) {
        fill: #000!important;
      }
    }
  }
}

.c-slider-case__item {
  position: relative;
  .c-slider-description {
    padding: 15px 25px 15px;
    background: #fff;
    bottom: 0;
    left: 0;
    color: $primaryColor;
    @include mq(pc) {
      position: absolute;
      width: 570px;
    }
    @include mq(sp) {
      padding: 10px 20px;
    }
  }
  .c-slider-description__catch {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.5;
    @include mq(sp) {
      font-size: 1.6rem;
    }
    .num {
      font-size: 3.6rem;
      font-weight: normal;
      margin-right: 14px;
      font-style: italic;
      @include mq(sp) {
        display: block;
        font-size: 1.8rem;
        line-height: 1;
      }
    }
    br {
      display: none;
    }
  }
  .c-slider-description__lead {
    margin-top: 5px;
    @include mq(sp) {
      margin-top: 0;
    }
  }
}

.c-slider-case-navigation {
  margin-top: 40px;
  @include mq(sp) {
    margin-top: 20px;
    width: 100vw;
  }
  .slick-track {
    // width: 100%!important;
    // transform:none!important;
  }
  .slick-list {
    @include mq(pc) {
      width: 1128px!important;
    }
  }
  &:focus {
    * {
      outline: none!important;
    }
  }
}

.c-slider-case-navigation__inner {
  // display: flex!important;
  // flex-wrap: wrap;
  // width: 100%!important;
  @include mq(pc) {
    width: 1128px!important;
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.c-slider-case-navigation__item {
  position: relative;
  text-align: center;
  width: 133px!important;
  height: 72px;
  color: #cccccc;
  font-size: 1.3rem;
  line-height: 1.4;
  @include mq(pc) {
    display: flex;
    align-items: center;
    justify-content: center;
    float:left;
    margin: 4px;
  }
  @include mq(sp) {
    width: 32vw!important;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #444444;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    @include mq(sp) {
      width: 95%;
    }
  }
  &::before {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 48%;
    height: 4px;
    background: transparent;
    margin-left: -30px;
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 48%;
    height: 4px;
    background: #fff;
    transform: scale(0, 1);
    transform-origin: center top;
    transition: transform .3s;    
    margin-left: -30px;
  }  
  &.-is-current {
    &::before {
      background: #fff;
    }
    div {
      background: $primaryColor;
      font-weight: bold;
      color: #fff;
      border: solid 1px #444444;
    }
  }
  &:hover {
    cursor: pointer;
    &::after {
      transform: scale(1, 1);
    }
    div {
      background: $primaryColor;
      font-weight: bold;
      color: #fff;
      border: solid 1px #444444;      
    } 
  }
  &:focus {
    outline: none!important;
  }
}

.slick-prev {
  background: #000;
}