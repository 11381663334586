.l-wrapper {
  .l-footer {
    margin-top: 0;
  }
}

.l-footer {
  margin-top: 100px;
  padding: {
    top: 110px;
    bottom: 40px;
  }
}

.c-footer {
  background: $primaryColor;
  color: #fff;
  .c-button-box {
    margin-top: 46px;
  }
  .c-button-basic {

  }
}

.c-footer__logo {
  text-align: center;
}

.c-footer__infomation {
  margin-top: 45px;
  text-align: center;
}

.c-infomation-tel__lead {
  font-size: 1.8rem;
}

.c-footer-link-company {
  display: block;
  margin: 10px 0 0 30px;
  color: #fff;
  font-size: 1.4rem;
  svg {
    margin-right: 10px;
    width: 21px;
    height: 14px;
    fill:#fff;
  }
  &:hover {
    opacity: .4;
  }
}

.c-footer-navigation {
  margin-top: 80px;
  @include mq(pc) {
    display: flex;
  }
}

.c-footer-navigation__item {
  flex: 0 1 calc(100% / 3);
  @include mq(pc) {
    width: calc(100% / 3);
  }
  &:not(:first-child) {
    @include mq(pc) {
      border-left: 1px solid #333;
      padding-left: 40px;      
    }
  }
  &:not(.-is-last) {
    .c-footer-navigation__list {
      @include mq(sp) {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .c-footer-navigation__child {
      &:nth-child(even) {
        @include mq(sp) {
          padding-left: 5%;
        }
      }
    }
  }
}

.c-footer-navigation__heading {
  font-weight: bold;
  @include mq(sp) {
    margin-bottom: 15px;
    padding: 8px 0;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
    font-size: 1.4rem;
  }
}

.c-footer-navigation__list {
  margin-top: 15px;
}

.c-footer-navigation__label {
  color: #fff;
  transition: $transition;
  position: relative;
  padding-left: 28px;
  @include mq(pc) {
    &:hover {
      opacity: .4;
    }
  }
  @include mq(sp) {
    display: block;
    padding: 4px 0 4px 15px;
  }
  &::before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    content: "";
    background: url('/assets/images/renewal/common/arrow-right.svg') no-repeat 0 0;
    width: 15px;
    height: 10px;
    @include mq(sp) {
      width: 10px;
    }
  }
}

.c-footer-navigation__child {
  @include mq(sp) {
    flex-basis: 50%;
  }
}

.c-footer-extra {
  margin-top: 80px;
  transition: $transition;
  @include mq(pc) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    a {
      &:hover {
        opacity: .4;
      }
    }
  }  
  @include mq(sp) {
    margin-top: 30px;
  }
}

.c-footer-extra__list {
  @include mq(pc) {
    display: flex;
    margin-left: -5px;    
  }
  a {
    color: #fff;
    padding: 0 5px;
    display: block;
    @include mq(sp) {
      padding: 14px 0;
    }
  }
}

.c-footer-extra__item {
  @include mq(pc) {
    margin-right: 10px;
  }
}

.c-footer-extra__company {
  @include mq(sp) {
    text-align: center;
    margin-top: 25px;
  }
}

.c-footer-copy {
  flex-basis: 100%;
  color: #999;
  @include mq(sp) {
    display: block;
    margin: 40px 0 10px;
    text-align: center;
  }
}