.-number-bold {
	font-family: 'numberBoldEX',"Noto Sans JP",sans-serif;
}

.p-price-anchor {
	padding-top: 40px;
	padding-bottom: 40px;
	.c-tab-controller__btn {
		&[aria-selected="true"] {
			border-bottom: none;
		}
		&:hover {
			background: #000;
		}
	}
}

.p-price-rental__table {
	.c-table-element {
		&:nth-child(n+2) {
			font-size: 2.8rem;
			font-weight: bold;
			@include mq(sp) {
				font-size: 1.8rem;
			}			
		}
	}
}

.p-price-option__table {
	.c-table-element {
		&:nth-child(n+2) {
			font-size: 2.8rem;
			font-weight: bold;
			@include mq(sp) {
				font-size: 1.8rem;
			}
			span {
				font-size: 1.6rem;
				font-weight: normal;
			}
		}
	}	
}

.p-price-airport__table {
	.c-table-element {
		font-family: 'numberBoldEX',"Noto Sans JP",sans-serif;
		&.-is-large {
			padding: 10px 0;
			font-size: 2.8rem;
			font-weight: bold;
			@include mq(sp) {
				font-size: 1.8rem;
			}
		}
	}
	.c-table__heading-secondary {
		border-bottom: none;
		border-top: none;
	}
	.-bg-goldLight {
		border-top: 2px solid #fff;
		border-bottom: 2px solid #fff;
	}
	.c-table-element {
		padding: 24px 0;
	}
}

.p-service {
	.p-merit__photo {
		@include mq(pc) {
			width: 745px;
		}
	}
	.p-merit__lead {
		@include mq(pc) {
			max-width:330px;	
		}
	}
}

