
//トップページだけスクロールしたらheaderが縮小する
.p-top {
	.c-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba($primaryColor,.7);
		@include z-index(header);
		transition: .5s;
		@include mq(pc) {
			height: 90px;
		}
		@include mq(sp) {
			background: none;
		}
		&.-is-scroll {
			animation-name: header-down;
			animation-fill-mode: both;
			animation-duration: .5s;
			position: fixed;
			background: $primaryColor;
			height: 64px;
			.c-header__logo {
				width: 88px;
				padding: 0;
				top: 50%;
				left: 20px;
				transform: translateY(-50%);
			}
			.c-header__logo-lead {
				display: none;
			}
			.c-globalNav-controller {
				&::before {
					background: #fff;
				}
			}
			.c-globalNav-controller__button {
				&::before,&::after {
					background: #fff;
				}      
			}
		}				
	}
}

//下層ページはheaderは固定
body:not(.p-top) {
	.c-header {
    position: fixed;
    background: $primaryColor;
		height: 64px;
		top: 0;
		left: 0;
		width: 100%;
		@include z-index(header);	
    .c-header__logo {
      width: 88px;
      padding: 0;
      top: 50%;
      left: 20px;
			transform: translateY(-50%);
			@include mq(sp) {
				position: absolute;
			}
    }
    .c-header__logo-lead {
      display: none;
    }
    .c-globalNav-controller {
      &::before {
        background: #fff;
      }
    }
    .c-globalNav-controller__button {
      &::before,&::after {
        background: #fff;
      }      
    }		
	}
}
.c-header {
  // position: absolute;
  // top: 0;
  // left: 0;
  // width: 100%;
  // background: rgba($primaryColor,.7);
  // @include z-index(header);
  // transition: .5s;
  // @include mq(pc) {
  //   height: 90px;
  // }
  // @include mq(sp) {
  //   background: none;
  // }
  // &.-is-scroll {
    // animation-name: header-down;
    // animation-fill-mode: both;
    // animation-duration: .5s;
    // position: fixed;
    // background: $primaryColor;
		// height: 64px;
		// top: 0;
		// left: 0;
		// width: 100%;		
    // .c-header__logo {
    //   width: 88px;
    //   padding: 0;
    //   top: 50%;
    //   left: 20px;
    //   transform: translateY(-50%);
    // }
    // .c-header__logo-lead {
    //   display: none;
    // }
    // .c-globalNav-controller {
    //   &::before {
    //     background: #fff;
    //   }
    // }
    // .c-globalNav-controller__button {
    //   &::before,&::after {
    //     background: #fff;
    //   }      
    // }
	// }
	@at-root {
		body.-is-jumbowagon,body.-is-kukousougei {
			.c-header {
				background: #ffffff;
				border-bottom: 1px solid #000;
				@include mq(pc) {
					height: 74px;
				}
			}
			.c-header__logo {
				background: #fff!important;
				width: 150px!important;
				@include mq(sp) {
					width: 124px!important;
				}
				img {
					width: 100%!important;
					height: auto!important;
				}
			}
			.c-globalNav {
				display: none;
			}
		}
		body.-is-kukousougei {
			.c-header__logo {
				width: 108px;
				img {
					@include mq(sp) {
						height: auto;
					}				
				}
			}
		}
	}

  &.-is-open {
    .c-globalNav-controller {
      &::before {
        background: #ffffff;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
    .c-globalNav-controller__button {
      &::before,&::after {
        background: #ffffff;
      }
      &::before {
        opacity: 0;
      }
      &::after {
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    .c-header__logo-lead {
      display: none;
    }
    .c-globalNav {
      transform: translateX(0);
    }
  }
}

.c-header__inner {
  position: relative;
  height: 100%;
  @include mq(sp) {
    display: flex;
    flex-wrap:wrap;
    padding: 24px 0;
    &.l-inner--lg {
      width: 100%;
    }
  }
}

.c-header__logo {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 12px;
  background: #000;
  width: 124px;
  color: #fff;
  @include mq(sp) {
    padding: 12px;
    position: fixed;
    z-index: 100;
    img {
      display: block;
      width: 80px;
      height: 38px;
      margin: 0 auto;
    }
  }
  &-lead {
    text-align: center;
    margin-top: 10px;
    font-size: 1.1rem;
    @include mq(sp) {
      margin-top: 14px;
    }
  }
}

.c-globalNav {
  height: 100%;
  @include mq(pc) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  @include mq(sp) {
    // padding-top: 42px;
    order: 3;
    position: fixed;
    width: 100%;
    top: 0;
    // height: 100vh;
    background: $primaryColor;
    overflow-y: scroll;
    z-index: 99;
    padding: 90px 6vw 15vw;
    transform: translateX(100%);
    transition: $transition;
  }
  .c-button-basic {
    flex: 0 1 160px;
    border-radius: 21px;
    font-weight: normal;
    @include mq(pc) {
      margin: 0 0 0 12px;
      height: 42px;
      font-weight: 500;
      font-size: 1.6rem;
    }
    @include mq(sp) {
      margin: 30px auto 0;
      width: 72.5vw;
      height: 64px;
      border-radius: 32px;
      // font-size: 2.4rem;
    }
    .icon-tel {
      width: 17px;
      margin-right: 5px;
    }
    .c-module-tel__txt {
      margin-right: 6px;
      font-size: 1.2rem;
    }
  }
  .c-button-border {
    flex: 0 1 118px;
    height: 34px;
    font-size: 1.2rem;
    @include mq(pc) {
      margin: 0 12px;
    }
    @include mq(sp) {
      margin: 30px auto 0;
      width: 72.5vw;
      height: 55px;
      font-size: 1.4rem;
    }  
  }
  .c-module-conversion-tel {
    @include mq(sp) {
      margin-top: 45px;
      .c-module-tel {
        font-size: 3.5rem;
        letter-spacing: normal;
      }
    }

  }
}

.c-globalNav__list {
  @include mq(pc) {
    display: flex;
    height: 100%;
    align-items: center;
  }
}

.c-globalNav__item {
  height: 100%;
}

.c-globalNav__label {
  position: relative;
  padding: 0 12px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  @include mq(pc) {
    @include appearBorder;
  }
  @include mq(sp) {
    padding: 20px 0 20px 12px;
    border-bottom: 1px solid #444;
    font-size: 1.4rem;
  }
}

.c-globalNav-controller {
  @include mq(sp) {
    position: fixed;
    width: 30px;
    height: 18px;
    margin-left: auto;
    right: 20px;
    z-index: 100;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $primaryColor;
      transition: .3s;
    }
  }
}

.c-globalNav-controller__button {
  @include mq(sp) {
    width: 100%;
    height: 100%;
    padding: 0;
    display: block;
    position: relative;
    opacity: 1;
    text-indent: 100%;
    background: none;
    &::before,&::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background: $primaryColor;
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      transition: .3s;
    }
    &::before {
      top: 50%;
      transform: translateY(-50%);
    }
    &::after {
      bottom: 0;
    }
  }
}
