@font-face {
  font-family: 'numberBoldEX';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat'), local('Montserrat-ExtraBold'),
			 url('/assets/fonts/montserrat-v14-latin-800.woff') format('woff');
			 unicode-range: U+0030-0039;
}
@font-face {
  font-family: 'numberBold';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat'), local('Montserrat-Medium'),
			 url('/assets/fonts/montserrat-v14-latin-500.woff') format('woff');
	unicode-range: U+0030-0039;
}