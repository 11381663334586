.p-top {
  .l-main {
    overflow-x: hidden;    
  }

  .p-keyVisual {
    position: relative;
    height: 628px;
    background: url(/assets/images/renewal/top/keyvisual_pc.jpg) no-repeat center top;
    @include mq(sp) {
      height: 440px;
      background: url(/assets/images/renewal/top/keyvisual_sp@2x.jpg) no-repeat center top / cover;
    }
  }

  .p-keyVisual__catch {
    position: absolute;
    font-size: 11rem;
    font-weight: 800;
    color: #fefefe;
    @include lang-en;
    line-height: 1.09;
    padding-left: 37px;
    text-shadow: 0 0 15px rgba(#2c2c2c,.5);
    @include mq(pc) {
      top: 220px;
    }
    @include mq(sp) {
      font-size: 5.5rem;
      bottom: 54px;
      padding-left: 0;
    }
  }
  
  .p-feature {
    padding: {
      top: 100px;
    }
    position: relative;
    @include mq(sp) {
      padding-top: 50px;
    }
    &::before {
      position: absolute;
      content:"";
      background: #000;
      width: 100%;
      height: 420px;
      left: 0;
      top: 0;
    }
    .l-inner {
      position: relative;
    }
  }

  .p-feature__heading {
    text-align: center;
    color: #fff;
    font-size: 3.6rem;
    font-weight: bold;
    line-height: 1.39;
    @include mq(sp) {
      font-size: 2.6rem;
    }
  }

  .p-feature__lead {
    text-align: center;
    margin: 40px auto 0;
    max-width: 800px;
    color: #fff;
    @include mq(sp) {
      margin-top: 40px;
      max-width: 339 / (400 / 100vw);
      text-align: left;
    }
  }

}

.p-merit__box {
  position: relative;
  width: 100%;
  @include mq(pc) {
    min-height: 490px;
  }
  &.-box01 {
    .p-merit__photo {
      background: url('/assets/images/renewal/top/photo_example_01.jpg') no-repeat 0 0 / cover;
    }
  }
  &.-box02 {
    .p-merit__photo {
      background: url('/assets/images/renewal/top/photo_example_02.jpg') no-repeat 0 0 / cover;
    }
	}
	@at-root {
		.p-service {
			.p-merit__box {
				@for $i from 1 through 4 {
					&.-box0#{$i} {
						.p-merit__photo {
							background: url('/assets/images/renewal/service/photo_example_0#{$i}.jpg') no-repeat 0 0 / cover;
						}						
					}
				}
			}
		}
		.p-price {
			.p-merit__box {
				@for $i from 1 through 4 {
					&.-box0#{$i} {
						.p-merit__photo {
							background: url('/assets/images/renewal/price/photo_example_0#{$i}.jpg') no-repeat 0 0 / cover;
						}						
					}
				}
			}
		}
	}
  &:nth-of-type(n+2) {
    margin-top: 60px;
    @include mq(sp) {
      margin-top: 50px;
    }
  }
  &:nth-of-type(odd) {
    .p-merit__photo {
      @include mq(pc) {
        right: 0;
      }
    }
  }
  &:nth-of-type(even) {
    .p-merit__photo {
      @include mq(pc) {
        left: 0;
      }
    }
    .p-merit__ttl,.p-merit__lead {
      @include mq(pc) {
        margin-left: auto;
      }
    }
	}
	//説明文が長いとき
	&.is-sm {
		.p-merit__photo {
		@include mq(pc) {
				position: absolute;
				content:"";
				width: 745px;
				height: 397px;
			}
		}
		.p-merit__lead {
			@include mq(pc) {
				max-width: 340px;
			}			
		}
	}
}

.p-merit__ttl,.p-merit__lead {
  @include mq(pc) {
    position: relative;
    z-index: 2;
  }
}

.p-merit__photo {
  @include mq(pc) {
    position: absolute;
    content:"";
    top: 60px;
    width: 809px;
    height: 431px;
  }
  @include mq(sp) {
    width: 100%;
    height: 187px;
  }
}

.p-merit__ttl {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($primaryColor,.7);
  font-size: 3.4rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  line-height: 1.39;
  @include mq(pc) {
    width: 432px;
    height: 200px;
  }
  @include mq(sp) {
    padding: 25px 0;
    font-size: 2.2rem;
    background: $primaryColor;
  }
}

.p-merit__lead {
	margin-top: 35px;
	font-size: 1.6rem;
  @include mq(pc) {
    max-width: 257px;
  }
  @include mq(sp) {
    margin-top: 20px;    
  }
}

.p-case {
  color: #fff;
}

.p-lineUp__description {
  @include mq(pc) {
    display: flex;
    padding: 0 50px;
  }
} 

.p-lineUp__ttl {
  font-size: 2.8rem;
  font-weight: bold;
  flex-shrink: 0;
  @include mq(sp) {
    font-size: 2.4rem;
  }
  .-lang-en {
    display: block;
    font-size: .64em;
    color: #666;
  }
}

.p-lineUp__lead {
  @include mq(pc) {
    margin-left: 104px;
  }
}

.p-lineUp__photo {
  margin-top: 60px;
  @include mq(pc) {
    display: flex;
    justify-content: center;
  }
  @include mq(sp) {
    width: 350 / (400 / 100vw);
    margin: 30px auto 0;
  }
  img {
    display: block;
    @include mq(pc) {
      margin: 0 10px;
    }
    &:nth-of-type(n+2) {
      @include mq(sp) {
        margin-top: 20px;
      }
    }

  }
}