.c-button-border {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 4px;
  border: solid 1px #fff;
}

.c-button-basic {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 21px;
  font-weight: bold;
  transition: $transition;
  $colors: (
    blue: linear-gradient(to right, #437bf0, #2f52ce),
    purple:linear-gradient(to top, #1f3487, #ce2f75),
    black:linear-gradient(to top, #858585, #000000)
  );
  @each $name,$color in $colors  {
    &.-#{$name} {
      background: $color;
      color: #fff;
    }
  }
  svg {
    margin-right: 20px;
    width: 32px;
    height: 36px;
    fill: #fff;
  }  
  &:hover {
    opacity: $opacity;
  }
  @include mq(sp) {
    font-size: 1.8rem;
    border-radius: 32px;
  }
}

.c-button-box__item {
  flex: 0 1 290px;
  width: 290px;
  // flex-basis: 290px;
  height: 64px;
  font-size: 1.8rem;
  margin: 0 12px;
  @include mq(sp) {
    margin: 0 auto;
    width: 290 / (400 / 100vw);
    &:nth-of-type(n+2) {
      margin-top: 35px; 
    }
	}
	&.-is-long {
		width: 440px;
		flex: 0 1 440px;
		@include mq(sp) {
			margin: 0 auto;
			width: 100%;
			.c-button-basic {
				font-size: 1.6rem;
			}		
		}
	}
}

.c-button-box {
  @include mq(pc) {
    display: flex;
    justify-content: center;
  }
  .c-button-basic {
    border-radius: 32px;
    width: 100%;
    height: 100%;
  }
  &.-in-section {
    margin-top: 50px;
  }
}