.c-title {
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 4rem;
  font-style: italic;
  line-height: 1.3;
  @include mq(sp) {
		font-size: 2.6rem;
		line-height: 1.4;
	}
	.c-title-icon {
		display: block;
		text-align: center;
		margin: 0 auto 20px;
	}
}

.c-title--sm {
  @extend .c-title;
  font-style: normal;
	font-size: 3.6rem;
	@include mq(sp) {
		font-size: 2.2rem;
	}
	.c-title-icon {
		&.-is-watch {
			width: 57px;
		}
		&.-is-plane {
			width: 47px;
		}
		&.-is-calendar {
			width: 46px;
		}
		&.-is-hand {
			width: 46px;
		}
	}
}

.c-title-section__heading {
	font-size: 3rem;
	font-weight: bold;
	text-align: center;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.c-title--sub {
  margin-top: 14px;
  display: block;
  font-size: .66em;
  font-weight: 800;
  @include lang-en;
}

.c-title--lead {
  display: block;
  font-size: 0.5em;
}

.c-page-heading {
  padding: 150px 0 60px;
  text-align: center;
  background: #e8e8e8;
  font-size: 3.6rem;
	font-weight: bold;
	font-style: italic;
	@include mq(sp) {
		padding: 100px 0 40px;
		font-size: 2.6rem;
	}
}

.c-page-heading--en {
  display: block;
  font-size: .6em;
	@include lang-en;
}

$pages:'service','partners','beginners','price';

.c-page-summary {
	display: flex;
	align-items: center;
	@include mq(pc) {
		height: 588px;
	}
	@include mq(sp) {
		padding: 90 / (400 / 100vw) 0;
		min-height: 460 /  (400 / 100vw);
	}
  @each $page in $pages {
    &.-#{$page} {
      background: url(/assets/images/renewal/#{$page}/bg_heading_01.jpg) no-repeat top center / cover;
    }
  }
}

.c-page-summary__box {
  padding: 70px 0;
  text-align: center;
  width: 1020px;
  margin: 0 auto;
  background: rgba(#fff,.9);
  @include mq(sp) {
    padding: 35px 20px;
    width: 350 / (400 / 100vw);
  }  
}

.c-page-summary__heading {
  font-size: 3.6rem;
	font-weight: bold;
	line-height: 1.39;
	@include mq(sp) {
		font-size: 2.2rem;
		line-height: 1.68;
	}
  & + .c-page-summary__lead {
		margin-top: 35px;
		line-height: 1.63;
		font-size: 1.6rem;
		@include mq(sp) {
			text-align: left;
		}
  }
}