$breakpoints: (
  'pc': 'screen and (min-width: 768px)',//pc
  'sp': 'screen and (max-width: 767px)',//sp
  'laptop': 'screen and (max-width: 1280px) and (min-width:768px)',
  'kv': 'screen and (max-width: 1366px) and (min-width:768px)'
) !default;

@mixin letter-spacing($tracking) {
  letter-spacing: $tracking / 1000 + em;
}
$baseTracking: .06em;
$transition: .3s;
$spDesign: 750 / 100;
$opacity: .7;
$colorParagraph: #2c2c2c;

$primaryColor: #000;
$basicBlack:#2c2c2c;
$gray:#eff0f0;

$colors: (
  primaryColor:#000,
  gray: #eff0f0,
  white:#fff,
  midGray:#666,
	linkBlue:#1937aa,
	gold:#b7912e,
	goldex:#d1ba7d,
	goldLight:#efe6cf,
	grayDark:#c9c9c9,
	grayLight:#eff0f0,
	goldMedium:#e6d5a9,
);

@each $name,$color in $colors {
  .-c-#{$name} {
    color: $color !important;
  }
  .-bg-#{$name} {
    background-color: $color;
  }
  .-fill-#{$name} {
    fill: $color;
  }
}

$z-index: (
  'header': 100,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}