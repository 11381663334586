.c-section__lead {
  margin-top: 35px;
  text-align: center;
	max-width: 880px;
	font-size: 1.6rem;
  margin: {
    left: auto;
    right: auto;
  }
  @include mq(sp) {
    text-align: left;
  }
}

.c-section__content {
  margin-top: 45px;
  @include mq(sp) {
    margin-top: 25px;
  }
}