.-hover-black {
  position: relative;
  transition: $transition;
  &::before {
    transition: $transition;
    position: absolute;
    top: 0;
    left: 0;
    content:"";
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 2;
  }
  &:hover {
    .-hover-black__inner {
      color: #fff;
    }
    .icon-arrow {
      fill: #fff;
    }
    &::before {
      background: rgba(#000,.7);
    }
  }
}

.-hover-black__inner {
  position: relative;
  z-index: 3;
}

@keyframes header-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@include mq(pc) {
  .-hover-opacity {
    transition: $transition;
    &:hover {
      opacity: $opacity;
      cursor: pointer;
    }
  }
}

@mixin appearBorder {
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 4px;
    background: #fff;
    transform: scale(0, 1);
    transform-origin: center top;
    transition: transform .3s;    
  }
  &:hover {
    &::after {
      transform: scale(1, 1);
    } 
  }
}
