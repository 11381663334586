.c-card {
  display: flex;
  @include mq(sp) {
    flex-wrap: wrap;
  }
}

.c-card-module {
  display: flex;
  &--3col {
    @extend .c-card-module;
    justify-content: space-between;
    @include mq(pc) {
      margin: {
        left: -10px;
        right: -10px;
      }
    }
    @include mq(sp) {
      display: block;      
    }
    .c-card-module__item {
      @include mq(pc) {
        flex: 0 1 calc(100% / 3);
        width: calc(100% / 3);
        margin: {
          left: 10px;
          right: 10px;
        }
      }
      @include mq(sp) {
        &:nth-child(n+2) {
          margin-top: 20px;
        }
      }
    }
  }
  &--2col {
    @extend .c-card-module;
    flex-wrap: wrap;
    @include mq(pc) {
      margin: {
        left: -10px;
        right: -10px;
      }
    }
    @include mq(sp) {
      display: block;      
    }    
    .c-card-module__item {
      padding: 35px 30px 35px;
      @include mq(pc) {
        flex: 0 1 calc(100% / 2);
        max-width: 548px;
        margin: 10px;
      }
      @include mq(sp) {
        &:nth-child(n+2) {
          margin-top: 20px;
        }
      }
    }    
  }
}

.c-card-module__item {
  background: #fff;
  box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
}

.c-card__item {
  flex: 0 1 calc(100% / 4);
  width: calc(100% / 4);
  &:not(:last-child) {
    border-right: 1px solid #dddddd;
  }
  @include mq(sp) {
    flex: 0 1 calc(100% / 2);
  }
  &-inner {
    display: block;
    width: 100%;
    height: 100%;
	}
	&.-is-step-flow {
		position: relative;
		@include mq(sp) {
			width: 100%;
			flex-basis: 100%;
			box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
		}
		&::after {
			position: absolute;
			content: "";
			background: url(/assets/images/renewal/common/cricle-arrow-below.svg) no-repeat 0 0 #fff;
			width: 50px;
			height: 50px;
			right: -25px;
			bottom: 30px;
			transform: rotate(-90deg);
			@include mq(sp) {
				z-index: 3;
				left: 50%;
				bottom: -35px;
				transform: translateX(-50%);
				background: url(/assets/images/renewal/common/cricle-arrow-below.svg) no-repeat 0 0;
			}
		}		
		&:last-child {
			&::after {
				content:none;
			}
		}
		&:nth-child(n+2) {
			@include mq(sp) {
				margin-top: 30px;	
			}
		}
		.c-card__photo {
			@include mq(sp) {
				padding-top: 71.5vw;
			}			
		}
		.c-card__lead {
			justify-content: center;
			text-align: center;
		}
	}
}

.c-card__photo {
  padding-top: 14.58vw;
  background-size: cover;
  background-position: center top;
  @include mq(sp) {
    padding-top: 40vw;
  }
}

.c-card__lead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 25px 25px 25px 30px;
  @include mq(sp) {
    font-size: 1.5rem;
    padding: 2.4vw 2vw;
  }
  .icon-arrow {
    width: 21px;
    height: 14px;
    @include mq(sp) {
      width: 12px;
      height: 8px;
    }
  }
}

.c-media {
  margin-top: 60px;
  @include mq(pc) {
    display: flex;
    justify-content: center;
  }
  @include mq(sp) {
    margin-top: 30px;
  }
}

.c-media__item {
  position: relative;
  flex: 0 1 360px;
  @include mq(pc) {
    margin: 0 10px;
  }
  @include mq(sp) {
    &:nth-of-type(n+2) {
      margin-top: 50px;
    }
  }
}

.c-media__box {
  position: relative;
  margin: -80px auto 0;
  padding: 35px 30px 34px;
  background: rgba(#fff,.9);
  box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  z-index: 2;
}

.c-media__catch {
  text-align: center;
  font-size: 2.4rem;
  font-weight: bold;
	line-height: 1.54;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.c-media__lead {
  margin-top: 25px;
}

.c-number-card {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
}

.c-number-card__item {
  position: relative;
  flex: 0 1 280px;
  background: #fff;
  @include mq(sp) {
    flex: 0 1 50%;
  }
  @for $num from 1 through 8 {
    &.-number-0#{$num} {
      .c-number-card__inner {
        &::before {
          content: "0#{$num}";
        }
      }
    }
  }
  &:nth-child(even) {
    @include mq(sp) {
      &::before {
        content:none;
      }
    }
  }
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: $gray;
    width: 1px;
    height: 100px;
  }
  @include mq(pc) {
    &:nth-child(n+5) {
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: $gray;
        width: 100px;
        height: 1px;      
      }
    }
  }
  @include mq(sp) {
    &:nth-child(n+3) {
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        background: $gray;
        width: 63px;
        height: 1px;      
      }  
    }
  }
}

.c-number-card__inner {
  position: relative;
  padding: 63px 30px 40px;
  @include mq(sp) {
    padding: 50px 15px 40px;
  }
  &::before {
    content:"01";
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    padding: {
      top: 15px;
      right: 5px;
    }
    line-height: 1;
    background: #000;
    color: #fff;
    @include lang-en;
    font-style: italic;
    font-size: 2.6rem;
    letter-spacing: 0.52px;
    @include mq(pc) {
      width: 50px;
      height: 45px;
    }
    @include mq(sp) {
      padding: 13px 9px 13px 7px;
      font-size: 1.6rem;
    }
  }
  &::after {
    content:"";
    position: absolute;
    top: 30px;
    left: 40px;
    border-radius: 50%;
    border: 16px solid #eee;
    width: 200px;
    height: 200px;
    box-sizing: border-box;
    @include mq(sp) {
      width: 126px;
      height: 126px;
      border: 16px solid #eee;
      left: 50%;
      transform: translateX(-50%);
    }
  }  
} 

.c-number-card__heading {
  position: relative;
  text-align: center;
  font-size: 2.4rem;
  z-index: 2;
  @include mq(sp) {
    font-size: 1.8rem;  
  }
  img {
    display: block;
    margin: 0 auto;
    @include mq(sp) {
      width: 60%;
    }
  }
}

.c-number-card__ttl {
  margin-top: 15px;
  font-weight: bold;
  line-height: 1.5;
  @include mq(sp) {
		line-height: 1.5;
		font-size: 2.2rem;
  }
}

.c-number-card__lead {
  margin-top: 30px;
  position: relative;
  z-index: 2;
  @include mq(sp) {
    margin-top: 20px;
  }
}

.c-plan-card {
  &.-add-photo {
    .c-plan-card__item {
      padding-top: 0;
      @include mq(pc) {
        display: flex;
      }
      &:nth-child(even) {
        .c-plan-card__photo {
          @include mq(pc) {
            order: -1;
          }
        }
      }
      &:nth-child(n+2) {
        margin-top: 50px;
      }
    }
    .c-plan-card__box {
      padding-top: 40px;
      flex: 0 0 360px;
      max-width: 360px;
      max-height: 426px;
    }
  }
}

.c-card_products__item {
	@include mq(pc) {
		padding: 0 20px;
	}
}

.c-card_products__photo {
	@include mq(pc) {
    margin-left: -20px;
    margin-right: -20px;
	}
}

.c-card_products__name {
  margin-top: 30px;
  font-size: 2.8rem;
	font-weight: bold;
	line-height: 1.39;
	@include mq(pc) {
		padding: 0 20px;
	}
	@include mq(sp) {
		margin-top: 20px;
		font-size: 2.2rem;
	}
}

.c-card_products__name-sub {
  margin-top: 10px;
  display: block;
  font-size: 0.64em;
  color: #666666;
}

.c-card_products__lead {
	margin-top: 20px;
	font-size: 1.6rem;
	line-height: 1.69;
}

.c-card_products__link {
  display: block;
  margin-top: 25px;
	color: #666666;
	@include mq(sp) {
		margin-top: 20px;
	}
	&:hover {
		opacity: .7;
	}
}

.c-plan-card__item {
  //padding: 40px 0 0;
  @at-root .p-top & .c-plan-card__box {
    display: block;
    padding-top: 40px;
    &:hover {
      opacity: .7;
    }
  }
  .icon {
    display: block;
    width: 102px;
    height: 102px;
    margin: 0 auto;
    fill: #d1ba7d;
  }
}

.c-plan-card__ttl {
  margin-top: 40px;
  padding: 10px 0;
  text-align: center;
  background: #d1ba7d;
  color: #fff;
  font-size: 2.4rem;
	font-weight: bold;
	@include mq(sp) {
		font-size: 2.2rem;
	}
}

.c-plan-card__lead {
  padding: 25px;
}

.c-feature-card__item {
  text-align: center;
}

.c-feature-card__heading {
  font-weight: bold;
	font-size: 2.4rem;
	line-height: 1.54;
	@include mq(sp) {
		font-size: 2.2rem;
		line-height: 1.4;
	}
  & + img {
		margin: 30px auto 0;
		width: 90px;
		@include mq(sp) {
			width: 35%;
		}
  }
}


.c-feature-card__lead {
  margin-top: 30px;
  text-align: left;
}

.c-side-card {
  display: flex;
  @include mq(sp) {
    flex-direction: column;
  }
  &:nth-child(n+2) {
    margin-top: 30px;
  }
}

.c-side-card__heading {
  font-weight: bold;
	font-size: 2.2rem;
	@include mq(sp) {
		line-height: 1.4;
	}
}

.c-side-card__photo {
  @include mq(pc) {
    flex: 0 0 560px;
    max-width: 560px;
  }
  @include mq(sp) {
    order: 2;
  }
}

.c-side-card__lead {
  margin-top: 35px;
  font-size: 1.6rem;
	line-height: 1.6;
	@include mq(sp) {
		margin-top: 15px;
	}
}

.c-side-card__summary {
	padding: 40px 40px 30px 50px;
	@include mq(sp) {
		padding: 25px;
	}
}