.l-wrapper {
  position: relative;
}

.l-inner {
  max-width: 1120px;
  margin: {
    left: auto;
    right: auto;
  }
  @include mq(sp) {
    width: 350 / (400 / 100vw);
  }
}

.l-inner--lg {
  @extend .l-inner;
  max-width: 1386px;
  @include mq(pc) {
    padding: 0 20px;
  }
}

.l-section-wrap {
  padding: {
    top: 110px;
    bottom: 100px;
  }
  @include mq(sp) {
    padding: {
      top: 40px;
      bottom: 60px;
    }
  }
  &--sm {
    padding: {
      top: 80px;
      bottom: 80px;
    }
    @include mq(sp) {
      padding: {
				top: 70px;
				bottom: 60px;
      }
    }    
  }
}

.l-section-block {
	padding-top: 150px;
	@include mq(sp) {
		padding-top: 60px;
	}
}