.p-beginners-rule__photo {
	padding: 50px 65px;
	background: #ffffff;
	max-width: 1020px;
	margin: 0 auto;
	@include mq(sp) {
		padding: 20px 10px;
	}
}

.p-beginners-support {
	.l-inner {
		max-width: 1360px;
	}
	.c-card {
		@include mq(pc) {
			box-shadow: 3px 5.2px 15px 0 rgba(0, 0, 0, 0.2);
		}
		@include mq(sp) {
			display: block;
		}
	}
	.c-title-section__heading {
		margin-bottom: 30px;
		@include mq(sp) {
			font-size: 2.2rem;
			line-height: 1.54;
		}
	}
}

.p-beginners-callcenter {
	.c-point-article {
		margin-top: 100px;
	}
}