
.only {
  &-pc {
    @include mq(sp) {
      display: none!important;
    }
  }
  &-sp {
    @include mq(pc) {
      display: none!important;
    }
  }
}
._flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  &-pc {
    @include mq(pc) {
      display: flex;
    }
  }
  &-between {
    justify-content: space-between;
    &-pc {
      @include mq(pc) {
        justify-content: space-between;
      }      
    }
    &-sp {
      @include mq(sp) {
        justify-content: space-between;
      }      
    }
  }
  &-wrap {
    flex-wrap: wrap;
    &-pc {
      @include mq(pc) {
        flex-wrap: wrap;
      }      
    }
    &-sp {
      @include mq(sp) {
        flex-wrap: wrap;
      }      
    }
  }
  &-center {
    justify-content: center;
  }
  &-al-center {
    align-items: center;
  }
}
._relative {
  position: relative;
}
._absolute {
  position: absolute;
}
._bold {
  font-weight: 700;
}

.-italic {
  font-style: italic;
}

.-lang-en {
  font-family: 'Montserrat', sans-serif;
}

._indent {
  text-indent: -1em;
  padding-left: 1em;
}

._center, ._ta-c {
	text-align: center !important;
	&-sp {
		@include mq(sp) {
      text-align: center !important;
    }
	}
}
._left, ._ta-l {
  text-align: left !important;
}
._right, ._ta-r {
  text-align: right !important;
  &-pc {
    @include mq(pc) {
      text-align: right !important;
    }
  }
}

._fl-l {
  float: left !important;
}
._fl-r {
  float: right !important;
}
._fl-c {
  margin-left: auto !important;
  margin-right: auto !important;
}

._clear {
  clear: both;
}
._clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

._cancel {
  text-decoration: line-through !important;
}

.-block,
.-d-b {
  display: block !important;
  &-sp {
    @include mq(sp) {
      display: block!important;
    }
  }
}
._d-i {
  display: inline !important;
}
._d-ib {
  display: inline-block !important;
}
._d-t {
  display: table !important;
  > * {
    display: table-cell;
    vertical-align: top;
  }
}
._d-n,
._hidden {
  display: none;
}

.is-hidden {
  visibility: hidden!important;
}
.is-none {
  display: none!important;
}

._no-border,
._bd-0 {
  border: none !important;
}
._bdt-0 {
  border-top: none !important;
}
._bdb-0 {
  border-bottom: none !important;
}
._bdr-0 {
  border-right: none !important;
}
._bdl-0 {
  border-left: none !important;
}

._no-bg {
  background: none !important;
}

._radius {
  border-radius: 4px !important;
}

._m-0,
._m0,
._no-m {
  margin: 0 !important;
}

._mt--0 {
  margin-top: 0 !important;
}

._p-0,
._p0,
._no-p {
  padding: 0 !important;
}

._pt-0 {
  padding-top: 0!important;
}
._pl-0 {
  padding-left: 0!important;
}
._mt-0 {
  margin-top: 0!important;
}
._mr-0 {
  margin-right: 0!important;
}
._ml-0 {
  margin-left: 0!important;
}
._normal {
  font-weight: normal;
}


@for $num from 0 through 150 {
  $margins: top, right, bottom, left;
  $classNames: mt, mr, mb, ml;
  @each $margin in $margins {
    $i: index($margins, $margin);
    $class: nth($classNames, $i);
    .-#{$class}-#{$num} {
      margin-#{$margin}: #{$num}px;
    }
  }
}
@for $num from 0 through 150 {
  $paddings: top, right, bottom, left;
  $classNames: pt, pr, pb, pl;
  @each $padding in $paddings {
    $i: index($paddings, $padding);
    $class: nth($classNames, $i);
    .-#{$class}-#{$num} {
      padding-#{$padding}: #{$num}px;
    }
  }
}
@include mq(pc) {
  @for $num from 1 through 150 {
    $margins: top, right, bottom, left;
    $classNames: mt, mr, mb, ml;
    @each $margin in $margins {
      $i: index($margins, $margin);
      $class: nth($classNames, $i);
      .-#{$class}-pc#{$num} {
        margin-#{$margin}: #{$num}px;
      }
    }
  }
}
@include mq(sp) {
  @for $num from 0 through 150 {
    $margins: top, right, bottom, left;
    $classNames: mt, mr, mb, ml;
    @each $margin in $margins {
      $i: index($margins, $margin);
      $class: nth($classNames, $i);
      .-#{$class}-sp#{$num} {
        $m: $num / 750 * 100;
        margin-#{$margin}: #{$m}vw;
      }
    }
  }
}
@include mq(pc) {
  @for $num from 0 through 150 {
    $paddings: top, right, bottom, left;
    $classNames: pt, pr, pb, pl;
    @each $padding in $paddings {
      $i: index($paddings, $padding);
      $class: nth($classNames, $i);
      .-#{$class}-pc#{$num} {
        padding-#{$padding}: #{$num}px!important;
      }
    }
  }
}
@include mq(sp) {
  @for $num from 0 through 150 {
    $paddings: top, right, bottom, left;
    $classNames: pt, pr, pb, pl;
    @each $padding in $paddings {
      $i: index($paddings, $padding);
      $class: nth($classNames, $i);
      .-#{$class}-sp#{$num} {
        padding-#{$padding}: #{$num}px!important;
      }
    }
  }
}

// .hover {
//   &-opacity {
//     &:hover {
//       transition: $transition;
//       opacity: $opacity;
//     }
//   }
// }
