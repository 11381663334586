.p-partners-relation {
	.c-section__lead {
		max-width: 1040px;
	}
}

.p-partners {
	.c-contact__lead {
		font-size: 1.8rem;
		@include mq(sp) {
			font-size: 1.5rem;
		}
	}
	.c-module-tel {
		margin-top: 20px;
		@include mq(sp) {
			font-size: 3.5rem;
		}
	}
}